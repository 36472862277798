<template lang="pug">
div
  v-btn(
    small
    color='primary'
    @click="checkReservationQueueBulk"
    :disabled="disabled"
    outlined
  ) Try to reserve
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  name: 'CandidateTryToReserve',

  inject: ['svc'],

  mixins: [ errorsMixin ],

  props: {
    disabled: Boolean,
    activeItems: Array,
  },

  methods: {
    async checkReservationQueueBulk() {
      try {
        const ids = this.activeItems.map(item => item.ID);
        await this.svc().checkReservationQueueBulk(ids)
        this.$notify({text: 'Trying to reserve', type: 'success'})
      } catch(err) {
        this.processError(err)
      }
    }
  }
}
</script>